var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "kuisioner",
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Formulir Gizi',
        href: '/',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "bg": "#FFF",
      "p": ['1rem', '0px']
    }
  }, [_c('Steps', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "current": 3,
      "total": 6
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px",
      "mx": "auto",
      "margin-top": ['16px', '24px']
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "text-transform": "uppercase",
      "font-weight": "500",
      "font-size": ['16px', '18px'],
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Formulir Gizi (3/6) ")]), _c('c-heading', {
    attrs: {
      "font-weight": "700",
      "font-size": ['18px', '28px'],
      "line-height": ['27px', '42px'],
      "color": "black.900"
    }
  }, [_vm._v(" Data Pola & Riwayat Makan ")])], 1), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input[type=radio]:checked': {
          bg: 'primary.400',
          borderColor: 'primary.400'
        },
        'input[type=checkbox]:checked': {
          bg: 'primary.400',
          borderColor: 'primary.400'
        },
        div: {
          fontSize: ['14px', '18px']
        }
      },
      expression: "{\n            'input[type=radio]:checked': {\n              bg: 'primary.400',\n              borderColor: 'primary.400',\n            },\n            'input[type=checkbox]:checked': {\n              bg: 'primary.400',\n              borderColor: 'primary.400',\n            },\n            div: {\n              fontSize: ['14px', '18px'],\n            },\n          }"
    }],
    attrs: {
      "margin-top": "24px"
    }
  }, [_c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.foodPreference.$invalid,
      "label": "Preferensi Makanan",
      "options": _vm.FOOD_PREFERENCES
    },
    model: {
      value: _vm.foodPreference,
      callback: function callback($$v) {
        _vm.foodPreference = $$v;
      },
      expression: "foodPreference"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.mainFoodConsumption.$invalid,
      "label": "Biasanya, berapa kali kamu makan berat atau makan utama dalam 1 hari?",
      "placeholder": "Bagaimana kebiasaan kamu",
      "options": _vm.MAIN_FOOD_CONSUMPTION
    },
    model: {
      value: _vm.mainFoodConsumption,
      callback: function callback($$v) {
        _vm.mainFoodConsumption = $$v;
      },
      expression: "mainFoodConsumption"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.snackConsumption.$invalid,
      "label": "Biasanya, berapa kali kamu makan cemilan dalam 1 hari?",
      "placeholder": "Jenis makanan",
      "options": _vm.SNACK_CONSUMPTION
    },
    model: {
      value: _vm.snackConsumption,
      callback: function callback($$v) {
        _vm.snackConsumption = $$v;
      },
      expression: "snackConsumption"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.foodSchedule.$invalid,
      "label": "Jelaskan jadwal atau jam makanmu dalam 1 hari",
      "placeholder": "Contoh: sarapan jam 8 pagi, makan siang jam 1, makan malam jam 7, cemilan jam 10 pagi dan jam 4 sore"
    },
    model: {
      value: _vm.foodSchedule,
      callback: function callback($$v) {
        _vm.foodSchedule = $$v;
      },
      expression: "foodSchedule"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.foodConsumptionType.$invalid,
      "label": "Masakan atau menu apa yang hampir setiap hari kamu konsumsi?",
      "placeholder": "Contoh: nasi uduk, telur dadar, ayam geprek, sayur bayam"
    },
    model: {
      value: _vm.foodConsumptionType,
      callback: function callback($$v) {
        _vm.foodConsumptionType = $$v;
      },
      expression: "foodConsumptionType"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.dislikeFood.$invalid,
      "label": "Makanan apa yang tidak kamu sukai?",
      "placeholder": "Mohon dituliskan nama bahan makanannya agar kami dapat membuat saran menu yang sesuai dengan selera kamu"
    },
    model: {
      value: _vm.dislikeFood,
      callback: function callback($$v) {
        _vm.dislikeFood = $$v;
      },
      expression: "dislikeFood"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.favoriteFood.$invalid,
      "label": "Kamu sukanya cita rasa makanan atau jenis makanan yang seperti apa?",
      "placeholder": "Contoh: Western food, japanese food, makanan khas minang, makanan khas manado, makanan khas jawa"
    },
    model: {
      value: _vm.favoriteFood,
      callback: function callback($$v) {
        _vm.favoriteFood = $$v;
      },
      expression: "favoriteFood"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.expectedBreakfast.$invalid,
      "label": "Masakan atau menu apa yang biasa kamu konsumsi saat sarapan?",
      "placeholder": "Mohon dituliskan jenis makanan yang biasa kamu konsumsi atau yang kamu harapkan muncul di menu sarapan kamu."
    },
    model: {
      value: _vm.expectedBreakfast,
      callback: function callback($$v) {
        _vm.expectedBreakfast = $$v;
      },
      expression: "expectedBreakfast"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.expectedLunchDinner.$invalid,
      "label": "Masakan atau menu apa yang biasa kamu konsumsi saat makan siang dan makan malam?",
      "placeholder": "Masukkan jenis makanan"
    },
    model: {
      value: _vm.expectedLunchDinner,
      callback: function callback($$v) {
        _vm.expectedLunchDinner = $$v;
      },
      expression: "expectedLunchDinner"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "input",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.foodAllergies.$invalid,
      "label": "Adakah makanan yang menyebabkan kamu alergi, jika ada sebutkan jenis makannya?",
      "placeholder": "Mohon dituliskan jenis makanan yang biasa kamu konsumsi atau yang kamu harapkan muncul di menu sarapan kamu."
    },
    model: {
      value: _vm.foodAllergies,
      callback: function callback($$v) {
        _vm.foodAllergies = $$v;
      },
      expression: "foodAllergies"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.fluidConsumption.$invalid,
      "label": "Berapa gelas rata-rata jumlah konsumsi air mineral (air putih) dalam satu hari?",
      "placeholder": "Contoh: 8 atau 6.5",
      "right-element": "Gelas"
    },
    model: {
      value: _vm.fluidConsumption,
      callback: function callback($$v) {
        _vm.fluidConsumption = $$v;
      },
      expression: "fluidConsumption"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.vegetablesConsumption.$invalid,
      "label": "Berapa banyak kamu mengonsumsi sayuran dalam sehari?",
      "options": _vm.VEGETABLES_CONSUMPTION
    },
    model: {
      value: _vm.vegetablesConsumption,
      callback: function callback($$v) {
        _vm.vegetablesConsumption = $$v;
      },
      expression: "vegetablesConsumption"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.fruitConsumption.$invalid,
      "label": "Berapa banyak kamu mengonsumsi buah dalam sehari?",
      "options": _vm.FRUIT_CONSUMPTION
    },
    model: {
      value: _vm.fruitConsumption,
      callback: function callback($$v) {
        _vm.fruitConsumption = $$v;
      },
      expression: "fruitConsumption"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.beveragesComsumption.$invalid,
      "label": "Berapa banyak kamu mengonsumsi minuman kemasan atau minuman manis dalam sehari?",
      "options": _vm.BEVERAGES_CONSUMPTION
    },
    model: {
      value: _vm.beveragesComsumption,
      callback: function callback($$v) {
        _vm.beveragesComsumption = $$v;
      },
      expression: "beveragesComsumption"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.friedSnackConsumption.$invalid,
      "label": "Berapa banyak kamu mengonsumsi cemilan gorengan (diluar lauk dalam makan utama) dalam sehari?",
      "options": _vm.FRIED_SNACK_CONSUMPTION
    },
    model: {
      value: _vm.friedSnackConsumption,
      callback: function callback($$v) {
        _vm.friedSnackConsumption = $$v;
      },
      expression: "friedSnackConsumption"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.saltySnackConsumption.$invalid,
      "label": "Berapa sering kamu mengonsumsi makanan ringan asin atau gurih dalam sehari (seperti makanan ringan kemasan, keripik, kacang goreng asin)?",
      "options": _vm.SALTY_SNACK_CONSUMPTION
    },
    model: {
      value: _vm.saltySnackConsumption,
      callback: function callback($$v) {
        _vm.saltySnackConsumption = $$v;
      },
      expression: "saltySnackConsumption"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.sweetSnackConsumption.$invalid,
      "label": "Berapa sering kamu mengonsumsi cemilan manis dalam sehari (seperti kue-kue manis, brownies, cake, biskuit, coklat, wafer)?",
      "options": _vm.SWEET_SNACK_CONSUMPTION
    },
    model: {
      value: _vm.sweetSnackConsumption,
      callback: function callback($$v) {
        _vm.sweetSnackConsumption = $$v;
      },
      expression: "sweetSnackConsumption"
    }
  }), _c('FormCheckbox', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.personServeFood.$invalid,
      "label": "Siapakah yang menyediakan makanan bagi kamu?",
      "options": _vm.PERSON_SERVE_FOOD
    },
    model: {
      value: _vm.personServeFood,
      callback: function callback($$v) {
        _vm.personServeFood = $$v;
      },
      expression: "personServeFood"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.milkFoodAndDrinkConsumption.$invalid,
      "label": "Apakah kamu bisa dan terbiasa konsumsi makanan dan minuman yang mengandung susu?",
      "placeholder": "Pilih Jawaban",
      "options": _vm.MILK_FOOD_AND_DRINK_CONSUMPTION
    },
    model: {
      value: _vm.milkFoodAndDrinkConsumption,
      callback: function callback($$v) {
        _vm.milkFoodAndDrinkConsumption = $$v;
      },
      expression: "milkFoodAndDrinkConsumption"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": false,
      "is-invalid": _vm.$v.lifestyle.$invalid,
      "label": "Tak kenal maka tak sayang.. Tolong ceritakan dong hal lain tentang pola makan dan gaya hidup kamu yang perlu kami tahu :)",
      "placeholder": "Masukkan cerita"
    },
    model: {
      value: _vm.lifestyle,
      callback: function callback($$v) {
        _vm.lifestyle = $$v;
      },
      expression: "lifestyle"
    }
  }), _c('c-grid', {
    attrs: {
      "margin-top": "16px",
      "template-columns": "repeat(2, 1fr)",
      "gap": "3"
    }
  }, [_c('c-button', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "color": "primary.400",
      "border-color": "primary.400",
      "border-radius": "100px",
      "variant": "outline",
      "px": "30px",
      "h": "48px",
      "is-disabled": _vm.isSubmitting || _vm.isBack
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "w": "100%",
      "background-color": "primary.400",
      "color": "white",
      "border-radius": "100px",
      "variant": "solid",
      "px": "30px",
      "is-disabled": _vm.isDisabled || _vm.isBack,
      "is-loading": _vm.isSubmitting,
      "loading-text": "Submitting",
      "h": "48px",
      "type": "submit"
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }